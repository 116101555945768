/* Loader
   ========================================================================== */
#loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bs-primary);
  height: 100%;
  width: 100%;
  opacity: 1;
}

.bricks {
  --cube-size: 25px;
  position: relative;
  width: calc(var(--cube-size) * 2);
  height: calc(var(--cube-size) * 2);
}

.brick {
  width: var(--cube-size);
  height: var(--cube-size);
  background: rgb(186, 186, 186);
  mix-blend-mode: exclusion;
  position: absolute;
  top: 0;
  left: 0;
  animation: loader-cube-1 3s infinite;
}
.brick:nth-of-type(2) {
  opacity: 0.5;
  right: 0;
  left: auto;
  animation-name: loader-cube-2;
  animation-delay: 0.7s;
}

@keyframes loader-cube-1 {
  from, to {
    transform: translate(0%);
  }
  25% {
    transform: translateY(100%);
  }
  50% {
    transform: translate(100%, 100%);
  }
  75% {
    transform: translate(100%);
  }
}
@keyframes loader-cube-2 {
  from, to {
    transform: translate(0);
  }
  25% {
    transform: translate(-100%);
  }
  50% {
    transform: translate(-100%, 100%);
  }
  75% {
    transform: translateY(100%);
  }
}